<script lang="ts">
	import type { Writable } from 'svelte/store'
	import Dialog from '$lib/components/dialogs/Dialog.svelte'
	import * as m from '$lib/paraglide/messages'
	import type { ChainConfig } from '$lib/constants/chains'
	import { getSocials } from '$lib/utils/chainHelpers'
	import ChainSocial from '$lib/components/buttons/ChainSocial.svelte'
	import { phg } from '$lib/utils/posthog'

	export let open: Writable<boolean>
	export let chainConfig: ChainConfig | null = null

	let state: 'question' | 'community-member-instructions' = 'question'

	open.subscribe(() => {
		state = 'question'
	})
</script>

<Dialog {open} title={m.get_listed_cta()}>
	{#if state === 'question'}
		<div class="prose text-center">
			<p>{m.chain_page_get_listed_dialog_question()}</p>
		</div>

		<div class="mt-5 flex items-center justify-center gap-4">
			<button
				on:click={() => {
					state = 'community-member-instructions'

					phg?.capture('get_listed_dialog_community_member_instructions')
				}}
				class="button button-outline button-sm"
			>
				{m.chain_page_get_listed_dialog_community_member()}
			</button>

			<a
				href="https://docs.google.com/forms/d/e/1FAIpQLSfRL5XJ-DpK08adpggqjZb71ru0Og1bnnUM-34yTe8dWTNyPw/viewform?usp=sf_link"
				target="_blank"
				class="button button-outline button-sm"
				on:click={() => {
					phg?.capture('get_listed_dialog_blockchain_team_form')
				}}
			>
				{m.chain_page_get_listed_dialog_team_member()}
			</a>
		</div>
	{:else if state === 'community-member-instructions'}
		<div class="prose">
			{chainConfig
				? m.chain_page_get_listed_dialog_specific_community_memeber_instructions({
						chain: chainConfig.name,
					})
				: m.chain_page_get_listed_dialog_general_community_memeber_instructions()}
		</div>

		{#if chainConfig}
			<div class="mt-3 flex flex-wrap items-center justify-center gap-2">
				{#each getSocials( chainConfig.socials, ['twitter', 'discord', 'reddit', 'telegram'] ) as { type, link }}
					<ChainSocial {type} {link} size="24" />
				{/each}
			</div>
		{/if}

		<div class="mt-6 flex items-center justify-stretch gap-6">
			<button
				on:click={() => {
					state = 'question'
				}}
				class="button button-outline button-sm !w-full"
			>
				{m.chain_page_get_listed_dialog_back()}
			</button>
		</div>
	{/if}
</Dialog>
